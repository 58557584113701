import { render, staticRenderFns } from "./UserReportHistoryRow.vue?vue&type=template&id=713cd9da&scoped=true&"
import script from "./UserReportHistoryRow.vue?vue&type=script&lang=js&"
export * from "./UserReportHistoryRow.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "713cd9da",
  null
  
)

export default component.exports