<template>
  <b-tr>
    <b-td class="white-space-nowrap">
      <div class="mb-1">
        <badge
          :status="userReport.status"
          :statuses="statuses"
        />
      </div>
      {{userReport.reportName}}
    </b-td>
    <b-td class="white-space-nowrap">
      <div>
        <strong>Richiesta:</strong>
        <br>
        {{userCreatedAt}}
      </div>
      <div class="mt-1">
        <strong>Ultima modifica:</strong>
        <br>
        {{userLastModified}}
      </div>
    </b-td>
    <b-td v-if="!smallTable">
      <div v-html="dataName" class="white-space-nowrap"></div>
      <div v-html="dataVat" class="white-space-nowrap"></div>
    </b-td>
    <b-td>
      <div>
        <strong>{{userReport.labelFeedbackType}}</strong>
        <br>
        {{userReport.labelFeedbackQuestion}}
        <b-button
          v-if="userReport.note"
          variant="link"
          @click="openNote"
          size="sm"
          class="text-info px-1 py-0 v-align-reset"
          v-b-tooltip.hover
          title="Note"
        >
          <font-awesome-icon icon="info-circle" />
        </b-button>
      </div>
    </b-td>
    <b-td
      class="white-space-nowrap table-cell--compact text-right vertical-align-middle"
      v-if="!smallTable"
    >
      <b-button
        variant="warning"
        @click="showDetail"
        size="sm"
        v-b-tooltip.hover
        title="Dettaglio"
      >
        <font-awesome-icon icon="info-circle"/>
      </b-button>
    </b-td>
  </b-tr>
</template>

<script>
import { formatDateTime, normalizeDisplayName } from '@/utils/formatterHelper';
import { isPresent } from '@/utils/validators';
import { USER_REPORT_CLASS } from '@/utils/interfaces';

const Badge = () => import('@/components/helpers/Badge.vue');

export default {
  name: 'UserReportHistoryRow',
  components: {
    Badge,
  },
  props: {
    openModalNote: Function,
    userReport: { ...USER_REPORT_CLASS },
    reportMe: Boolean,
    smallTable: Boolean,
    statuses: String,
  },
  computed: {
    userCreatedAt() {
      console.log('userReport', this.userReport);
      return formatDateTime(this.userReport.createdAt);
    },
    userLastModified() {
      return formatDateTime(this.userReport.lastModified);
    },
    referentToPerson() {
      return isPresent(this.userReport.referentInfo.personId);
    },
    reporterPerson() {
      return isPresent(this.userReport.reporterInfo.personId);
    },
    // dataPrice() {
    //   if (this.userReport.price) return this.userReport.price;
    //   return 'ND';
    // },
    dataName() {
      if (this.reportMe) {
        if (this.reporterPerson) {
          const a = normalizeDisplayName(this.userReport.reporterInfo.name);
          const b = normalizeDisplayName(this.userReport.reporterInfo.surname);
          return `${a} ${b}`;
        }
        if (isPresent(this.userReport.reporterInfo.companyName)) {
          return this.userReport.reporterInfo.companyName;
        }
      }
      if (!this.reportMe) {
        if (this.referentToPerson) {
          const a = normalizeDisplayName(this.userReport.referentInfo.name);
          const b = normalizeDisplayName(this.userReport.referentInfo.surname);
          return `${a} ${b}`;
        }
        if (isPresent(this.userReport.referentInfo.companyName)) {
          return this.userReport.referentInfo.companyName;
        }
      }
      return 'Non disponibile';
    },
    dataVat() {
      if (this.reportMe) {
        if (this.reporterPerson) {
          return `<strong>C.F.:</strong> ${this.userReport.reporterInfo.taxCode}`;
        }
        if (isPresent(this.userReport.reporterInfo.vatNumber)) {
          return `<strong>P.IVA:</strong> ${this.userReport.reporterInfo.vatNumber}`;
        }
      }
      if (!this.reportMe) {
        if (this.referentToPerson) {
          return `<strong>C.F.:</strong> ${this.userReport.referentInfo.taxCode}`;
        }
        if (this.userReport.referentInfo.vatNumber) {
          return `<strong>P.IVA:</strong> ${this.userReport.referentInfo.vatNumber}`;
        }
      }
      return '';
    },
  },
  methods: {
    showDetail() {
      // console.log('show detail');
      this.$router.push(`/user-report-detail/${this.userReport.reportName}`);
    },
    openNote() {
      // console.log('OpenNote');
      this.openModalNote(this.userReport);
    },
  },
};
</script>

<style scoped>

</style>
